// third-party

import { FormattedMessage } from 'react-intl';
// import { useEffect } from 'react';
import { useEffect, useState } from 'react';

// assets
import { KyberNetwork, Messages2, Calendar1, Kanban, Profile2User, Bill, UserSquare, ShoppingBag, TicketDiscount, CallIncoming, People, BoxTime, Card, MoneyRecive } from 'iconsax-react';

// icons
const icons = {
  applications: KyberNetwork,
  allcustomer: People,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  coupons: TicketDiscount,
  contact: CallIncoming,
  allorder: BoxTime,
  blogs: Card,
  payment: MoneyRecive
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //




let applications = {}


let adminapplications = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  icon: icons.applications,
  type: 'group',
  children: [

    {
      id: 'customer',
      title: <FormattedMessage id="Customer" />,
      type: 'collapse',
      icon: icons.customer,
      children: [
        {
          id: 'customer-list',
          title: <FormattedMessage id="All Customers" />,
          type: 'item',
          icon: icons.allcustomer,
          url: '/apps/customer/customer-list'
        }

      ]
    },



    {
      id: 'store',
      title: <FormattedMessage id="Store" />,
      type: 'collapse',
      icon: icons.customer,
      children: [
        {
          id: 'all-store',
          title: <FormattedMessage id="All Store" />,
          type: 'item',
          icon: icons.customer,
          url: '/apps/store/all'
        },
        {
          id: 'payment-transaction',
          title: <FormattedMessage id="Payment Transaction" />,
          type: 'item',
          icon: icons.payment,
          url: '/apps/store/payment-transaction'
        }
      ]
    },
    {
      id: 'vendor',
      title: <FormattedMessage id="Vendor" />,
      type: 'collapse',
      icon: icons.customer,
      children: [
        {
          id: 'vendors',
          title: <FormattedMessage id="Vendors" />,
          type: 'item',
          url: '/apps/vendor/vendors',
          icon: icons.blogs
        },
        {
          id: 'vendorproducts',
          title: <FormattedMessage id="Vendor Products" />,
          type: 'item',
          icon: icons.payment,
          url: '/apps/vendor/vendorproducts'
        },
        {
          id: 'vendorbrands',
          title: <FormattedMessage id="Vendor Brands" />,
          type: 'item',
          icon: icons.payment,
          url: '/apps/vendor/vendorbrands'
        },
        {
          id: 'add-product',
          title: <FormattedMessage id="Add Products" />,
          type: 'item',
          icon: icons.payment,
          url: '/apps/vendor/add-product'
        }, 
        {
          id: 'vendorstore',
          title: <FormattedMessage id="Vendor Store" />,
          type: 'item',
          icon: icons.payment,
          url: '/apps/vendor/vendor-store'
        },
        {
          id: 'allorders',
          title: <FormattedMessage id="All Orders" />,
          type: 'item',
          icon: icons.payment,
          url: '/apps/vendor/allorders/unfulfilled'
        }
      ]
    },
    {
      id: 'wallet',
      title: <FormattedMessage id="Wallets" />,
      type: 'collapse',
      icon: icons.customer,
      children: [
        {
          id: 'allwallets',
          title: <FormattedMessage id="All Wallets" />,
          type: 'item',
          url: '/apps/allwallets',
          icon: icons.allorder,
          breadcrumbs: false
        },
        {
          id: 'alltransactions',
          title: <FormattedMessage id="All Transactions" />,
          type: 'item',
          url: '/apps/alltransactions',
          icon: icons.allorder,
          breadcrumbs: false
        },
        {
          id: 'payment-transaction',
          title: <FormattedMessage id="Wallet Transaction" />,
          type: 'item',
          url: '/apps/payment-transaction',
          icon: icons.allorder,
          breadcrumbs: false
        },

      ]
    },



    {
      id: 'slideshow',
      title: <FormattedMessage id="Slideshow" />,
      type: 'item',
      url: '/apps/slideshow',
      icon: icons.blogs
    },







    {
      id: 'admin',
      title: <FormattedMessage id="Admin" />,
      type: 'item',
      url: '/apps/create-admin',
      icon: icons.blogs
    },


    {
      id: 'categories',
      title: <FormattedMessage id="Categories" />,
      type: 'item',
      url: '/apps/categories',
      icon: icons.blogs
    },


    {
      id: 'allorder',
      title: <FormattedMessage id="All order" />,
      type: 'item',
      url: '/apps/allorder',
      icon: icons.allorder,
      breadcrumbs: false
    },


    {
      id: 'offers',
      title: <FormattedMessage id="All Offers" />,
      type: 'item',
      url: '/apps/offers',
      icon: icons.allorder,
      breadcrumbs: false
    }


  ]
};








const applications1 = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  icon: icons.applications,
  type: 'group',
  children: [
    // {
    // id: 'store',
    // title: <FormattedMessage id="Store" />,
    // type: 'collapse',
    // icon: icons.customer,
    // children: [
    // {
    //   id: 'all-store',
    //   title: <FormattedMessage id="All Store" />,
    //   type: 'item',
    //   icon: icons.customer,
    //   url: '/apps/store/all'
    // },
    {
      id: 'payment-transaction',
      title: <FormattedMessage id="Payment Transaction" />,
      type: 'item',
      icon: icons.payment,
      url: '/apps/store/payment-transaction'
    },
    // ]
    // },


    {
      id: 'store-profile',
      title: <FormattedMessage id="Store Profile" />,
      type: 'item',
      url: '/apps/store-profile',
      icon: icons.blogs
    },

  ]
}



const storeUrl = localStorage.getItem('storeUrl')
if (storeUrl) {
  applications = applications1
}
else {
  applications = adminapplications
}













export default applications;
