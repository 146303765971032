import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { ToastContainer, toast } from 'react-toastify'; // Import toast related components and functions
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'components/Loader';
import axios from 'axios';
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import jwtDecode from 'jwt-decode';

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (token) => {
  if (!token) {
    return false;
  }
  return true;
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('storeUrl');
  }
};

const setUser = (user) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    localStorage.removeItem('user');
  }
};

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        let token = localStorage.getItem('token') || localStorage.getItem('rolestore');
        const user = JSON.parse(localStorage.getItem('user'));

        if (token && verifyToken(token)) {
          setSession(token);
          setUser(user);

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (error) {
        console.error('Error initializing:', error);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const server = process.env.REACT_APP_API_URL;

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${server}/api/admin/login`, { email, password });
      const { token } = response.data;

      setSession(token);
      if (token) {
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true
          }
        });
        window.location.reload();
      }
    } catch (error) {
      toast.error('Login Failed, Enter Correct Login Credentials'); // Display error using toast
      console.log('Error logging in:', error.response.data.message);
    }
  };

  const logout = async () => {
    setSession(null);
    setUser(null);
    dispatch({ type: LOGOUT });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: '#fff' }}
      />
      <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>
    </>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
